export const environment = {
  allowAll:false,
  production: true,
  //apiAuthUrl_: 'https://guichet-prod-auth-services.bgdigital.cloud/api',
  apiAuthUrl: 'https://proxy.pdg.sn/auth/api',
  //apiDashboard_: 'https://guichet-prod-dashboard-services.bgdigital.cloud/api',
  apiDashboard: 'https://proxy.pdg.sn/dashboard/api',
  //socketUrl_: 'wss://guichet-prod-dashboard-services.bgdigital.cloud/',
  socketUrl: 'wss://proxy.pdg.sn/dashboard/',
  //apiReseauUrl_:'https://guichet-prod-reseau-services.bgdigital.cloud/api',
  apiReseauUrl:'https://proxy.pdg.sn/reseau/api'
};
